<template>
  <div class="page-block">

    <i18n-selector />

    <span @click="logout" class="button logoutbutton w-inline-block underline-link"
      v-if="clientAuthType && clientAuthType !== 'SSO'">
      <img src="@/assets/images/logout-1.svg" loading="lazy" alt="" class="logouticon">
      <div>{{ $t('dashboardPage_logout') }}</div>
    </span>

    <span @click="goToMainMenu"
        class="button w-button backbutton">
      {{ $t('navigationMenu_goToBack') }}
    </span>

    <zhh-zedic-header :title="$t('hepProgram_testPage_title')" />

    <UploadTest v-if="loggedUser" :loggedUser="loggedUser"/>

  </div>
</template>

<script>
import AuthApi from '@/api/auth.api';
import Utils from '@/utils/utils';
import UploadTest from '@/components/hep-program/UploadTest.vue';

export default {
  name: 'HepProgram_UploadTestPage',
  components: {
    UploadTest,
  },

  data() {
    return {
      clientAuthType: undefined,
      loggedUser: undefined,
    };
  },

  created() {
    if (!this.isHepProgram()) {
      this.$router.push({ name: 'AuthErrorPage' });
    } else {
      this.fetchLoggedUser();
    }
  },

  methods: {
    isHepProgram() {
      return Utils.isHepProgramClient();
    },

    async fetchLoggedUser() {
      try {
        const loggedUserResult = await AuthApi.getMe();
        this.loggedUser = loggedUserResult.data;
        this.clientAuthType = this.loggedUser.auth;
      } catch (e) {
        console.error(e);
        this.showErroAlert({
          title: 'Oops!',
          text: this.$t('somethingWentWrong'),
        });
      }
    },

    goToMainMenu() {
      this.$router.push({ name: 'HepProgram_HomePage' });
    },

    showErroAlert(message) {
      const options = {
        ...message,
        // position: 'top-end',
      };
      return this.$swal(options);
    },

    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },
  },
};
</script>

<style scoped>

  .logoutbutton {
    width: 190px;
    color: #5031D0;
    text-decoration: none;
    position: absolute;
    right: 5%;
    margin-top: -25px;
    text-transform: uppercase;
  }

  .backbutton {
    position: absolute;
    left: 5%;
    margin-top: -25px;
    width: 100px;
  }

  .logouticon {
    width: 25px;
    margin-right: 12px;
  }

  @media screen and (max-width: 479px) {
    .backbutton {
      position: relative;
      left: 0px;
      margin-top: -10px;
      margin-bottom: 20px;
      width: 100%;
    }

    .logoutbutton {
      position: relative;
      right: 0px;
      margin-top: -10px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
</style>
