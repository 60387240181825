<template>
  <div>
    <date-pick v-model="selectedDate" :isDateDisabled="isDateDisabled">
      <template v-slot:default="{toggle, inputValue}">
        <button @click="toggle" class="datepicker-btn">
          {{ inputValue || $t('selectDate') }}
        </button>
      </template>
    </date-pick>
  </div>
</template>

<script>
import DatePick from 'vue-date-pick';

import 'vue-date-pick/dist/vueDatePick.css';

export default {
  name: 'DatePicker',
  props: ['value', 'isDateDisabled'],
  data() {
    return {
      selectedDate: this.value,
    };
  },
  components: {
    DatePick,
  },

  watch: {
    selectedDate() {
      this.$emit('input', this.selectedDate);
    },
  },
};
</script>

<style scoped>

  .datepicker-btn {
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    align-items: center;
    display: inline-grid;
  }

</style>
