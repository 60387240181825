<template>
  <div>
    <div v-if="loadingPage">
      <div class="spinning-loader"></div>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="!loadingPage && !loadingEmployeeData" class="page-block-item">

        <div v-if="!showMainPageArea && loadingEmployeeData" class="dataentrydiv">
          <div class="spinning-loader"></div>
        </div>

        <div v-if="showMainPageArea">
          <div v-if="otherTests && otherTests.length > 0">
            <div class="dataentrydiv margin-bottom-25">

              <div class="prior-input"
                v-for="otherTest in otherTests"
                v-bind:key="otherTest.id"
              >
                <span>
                  <div class="input-records-title">
                    {{ $t('hepProgram_testPage_testRecords') }}
                  </div>
                </span>
                <table
                  class="vaccination-records-table">
                  <tr>
                    <td
                      style="text-align: center;">
                      {{ otherTest.testDate }}
                    </td>
                  </tr>
                </table>
                <button class="delete-btn" @click="deleteRecord(otherTest.testDate)">
                  {{ $t('deleteRecords') }}
                </button>
              </div>
            </div>

            <div>
              <div class="general-text text-justify">
                <div >{{ $t('ifTestIncorrectUser') }}</div>
              </div>
            </div>

          </div>

          <div v-else class="dataentrydiv">
            <div class="general-text container hep-description">
              {{ $t('hepProgram_testPage_pageDescription') }}
            </div>

            <div class="input-label margin-bottom-15">
              {{ $t(`hepProgram_testPage_informDates`) }}
            </div>

            <div class="dataentrydiv">
              <div class="margin-bottom-5" >
                <div class="daterow margin-bottom-5">
                  <DatePicker class="datepick"
                    v-model="covidTestDate"
                    :isDateDisabled="isFutureDate" />
                </div>
              </div>
            </div>

            <div class="dataentrydiv margin-bottom-25 margin-top-25">
              <div class="input-label margin-bottom-15">
                {{ $t('hepProgram_testPage_uploadTest') }}
              </div>

              <vue-dropzone ref="uploadVueDropzone" id="dropzoneId"
                :options="dropzoneOptions" :useCustomSlot="true"
                @vdropzone-file-added="fileAdded"
                @vdropzone-removed-file="fileRemoved"
                @vdropzone-sending="uploadingImage"
                @vdropzone-error="errorUploading"
                @vdropzone-queue-complete="uploadCompleted">
                <div class="dropzone-custom-content">
                  <img src="@/assets/images/upload.svg" loading="lazy" alt="" class="uploadicon">
                  <div>{{ $t('uploadAreaInfo') }}</div>
                </div>
              </vue-dropzone>
            </div>

            <div class="dataentrydiv" style="width: 100%">
              <span v-if="!submitting" @click="submitForm" class="button w-button">
                {{ $t('hepProgram_testPage_saveYourChanges') }}
              </span>
              <span v-else class="button w-button">
                <div class="lds-loader"><div></div><div></div><div></div></div>
              </span>
            </div>

            <p class="general-text margin-top-25">
              {{ $t('hepProgram_testPage_submitConsent') }}
            </p>

          </div>
        </div>

        <div class="dataentrydiv new-section-page">
          <privacy-policy />
        </div>

      </div>
    </transition>

  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import { uuid } from 'vue-uuid';
import Path from 'path';

import AuthApi from '@/api/auth.api';
import EmployeeApi from '@/api/employee.api';
import UploadApi from '@/api/upload.api';
import Utils from '@/utils/utils';

import DatePicker from '@/components/common/DatePicker.vue';

import 'vue2-dropzone/dist/vue2Dropzone.min.css';

const CLIENT = Utils.getClient();

export default {
  name: 'HepProgram_UploadTest',
  props: ['loggedUser'],

  components: {
    DatePicker,
    vueDropzone,
  },

  data() {
    return {
      loadingPage: true,
      loadingEmployeeData: false,
      showMainPageArea: false,

      selectedEmployee: null,
      selectedRestaurant: null,
      covidTestDate: '',
      covidTestResult: '',
      clientId: CLIENT,
      contactEmail: '',
      phoneNumber: '',
      dropzoneOptions: {
        url: UploadApi.getOtherTestEndpoint(),
        acceptedFiles: 'image/*,application/pdf',
        autoProcessQueue: false,
        addRemoveLinks: true,
        withCredentials: true,
        headers: {
          'x-sub-client': CLIENT,
        },
      },
      uploadedFiles: [],
      uploadHasError: false,
      modalImgURL: '',
      submitting: false,
      selectableRestaurantLoaded: false,
      userName: {
        firstName: '',
        lastName: '',
      },
      otherTests: [],
    };
  },

  created() {
    this.fetchInitialData();
  },

  methods: {
    resetForm() {
      this.covidTestDate = '';
      this.covidTestResult = '';
      this.contactEmail = '';
      this.phoneNumber = '';
      this.uploadedFiles = [];
      this.userName.firstName = '';
      this.userName.lastName = '';
      this.selectedRestaurant = null;
    },

    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },

    fileAdded() {
      const existingFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      Object.keys(existingFiles).forEach((key) => {
        this.$refs.uploadVueDropzone.removeFile(existingFiles[key]);
      });
    },

    fileRemoved() {
      this.uploadedFiles = [];
    },

    uploadingImage(file, xhr, formData) {
      const fileName = `${uuid.v1()}${Path.extname(file.name)}`;
      formData.append('client', CLIENT);
      formData.append('employeeId', this.selectedEmployee);
      formData.append('fileName', fileName);
      formData.append('testDateMetadata', this.covidTestDate);
      this.uploadedFiles.push(fileName);
    },

    errorUploading() {
      this.uploadHasError = true;
      this.uploadedFiles = [];
      this.$refs.uploadVueDropzone.removeAllFiles();
    },

    uploadCompleted() {
      if (!this.uploadHasError) {
        // Complete sending data
        this.completeSubmitForm();
      } else {
        this.submitting = false;
        // Display error message
        this.showErroAlert({
          title: this.$t('uploadError'),
          text: this.$t('uploadErrorInst'),
        });
      }
    },

    submitForm() {
      this.submitting = true;
      this.uploadHasError = false;

      if (!this.validateForm()) {
        this.submitting = false;
        return;
      }

      const queuedFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      if (queuedFiles.length) {
        this.$refs.uploadVueDropzone.processQueue();
      } else {
        // Complete sending data
        this.completeSubmitForm();
      }
    },

    async deleteRecord(otherTestId) {
      const confirmation = await this.$swal({
        title: this.$t('areYouSure'),
        text: this.$t('noRevert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('deleteConfirm'),
      });
      if (confirmation.isConfirmed) {
        await EmployeeApi.deleteOtherTest(this.selectedEmployee, otherTestId);
        this.fetchEmployeeData();
      }
    },

    async completeSubmitForm() {
      const covidTestRecord = {
        clientId: CLIENT,
        employeeId: this.selectedEmployee,
        testDate: this.covidTestDate,
        testResult: 'POSITIVE',
        testType: 'HepA IGM+',
        images: [...this.uploadedFiles],
      };

      try {
        await EmployeeApi.submitOtherTest(this.selectedEmployee, covidTestRecord);
        await this.showSuccesslert({
          title: this.$t('success'),
          text: this.$t('uploadCovidTestPage_submitSuccess'),
        });

        this.fetchEmployeeData();
      } catch (e) {
        await this.showErroAlert({
          title: 'Oops..',
          text: this.$t('somethingWentWrong'),
        });
      }
      this.submitting = false;
    },

    validateForm() {
      if (!this.covidTestDate) {
        this.showWarninglert({
          title: this.$t('validationError'),
          text: this.$t('uploadCovidTestPage_pleaseSelectCovidTestDate'),
        });
        return false;
      }

      const queuedFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      if (this.uploadedFiles.length === 0 && queuedFiles.length === 0) {
        this.showWarninglert({
          title: this.$t('uploadCovidTestPage_missingCard'),
          text: this.$t('uploadCovidTestPage_mustUpload'),
        });
        return false;
      }
      return true;
    },

    getUploadTextObject() {
      const uploadText = {
        title: this.$t('uploadCovidTestPage_uploadCovidTestCopyTitle'),
      };

      if (CLIENT === 'bloomin') {
        uploadText.title = this.$t('uploadCovidTestPage_uploadCovidTestCopyBelowTitle');
      }

      if (CLIENT === 'flynnrg') {
        uploadText.extraText1 = this.$t('uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg1');
        uploadText.extraText2 = this.$t('uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg2');
        uploadText.extraText3 = this.$t('uploadCovidTestPage_uploadCovidTestExtraTextFlynnrg3');
      }

      return uploadText;
    },

    getUploadCovidTestPage_enterNameAndRestaurantText() {
      if (CLIENT === 'blazepizza'
          || CLIENT === 'centerplate'
          || CLIENT === 'saucepizzaandwine') {
        return this.$t('uploadCovidTestPage_enterNameAndLocationText');
      }
      if (CLIENT === 'windham') {
        return this.$t('uploadCovidTestPage_enterNameAndDepartmentText');
      }
      return this.$t('uploadCovidTestPage_enterNameAndRestaurantText');
    },

    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },

    showErroAlert(message) {
      return this.showAlert({
        ...message,
        icon: 'error',
      });
    },

    showWarninglert(message) {
      return this.showAlert({
        ...message,
        icon: 'warning',
      });
    },

    showSuccesslert(message) {
      return this.showAlert({
        ...message,
        icon: 'success',
      });
    },

    showAlert(message) {
      const options = {
        ...message,
        // position: 'top-end',
      };
      return this.$swal(options);
    },

    async fetchInitialData() {
      try {
        if (!this.loggedUser.roles?.includes('USER')) {
          this.$router.push({ name: 'AuthErrorPage' });
          return;
        }

        this.clientAuthType = this.loggedUser.auth;
        this.selectedEmployee = this.loggedUser.id;

        this.loadingPage = false;
      } catch (e) {
        this.showErroAlert({
          title: 'Oops!',
          text: this.$t('somethingWentWrong'),
        });
        this.loadingPage = false;
        // Should route to error page
      }
    },

    async fetchEmployeeData() {
      this.showMainPageArea = false;
      this.loadingEmployeeData = true;
      this.resetForm();
      try {
        const otherTestListResult = await EmployeeApi
          .getOtherTestList(this.selectedEmployee);

        this.otherTests = otherTestListResult.data;

        this.loadingEmployeeData = false;
        this.showMainPageArea = true;
      } catch (e) {
        this.loadingEmployeeData = false;
        this.showMainPageArea = true;
        // Should display error
      }
    },
  },

  computed: {

  },

  watch: {
    selectedEmployee() {
      if (this.selectedEmployee) {
        this.fetchEmployeeData();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .dz-progress {
    display: none !important;
  }

  /* Date Pick CSS */
  .vdpComponent {
    width: 100%;
  }

  .vdpComponent.vdpWithInput  > button {
    border: 1px solid#e8e8e8;
    border-radius: 8px;
  }

  .vdpCell.today {
    color: red;
  }

  .datepicker-btn {
    width: 270px !important;
    height: 40px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    align-items: center !important;
    display: inline-grid !important;
  }

  @media screen and (max-width: 479px) {
    .daterow .datepicker-btn {
      margin-top: 15px !important;
    }

    .datepicker-btn {
      width: 100% !important;
    }
  }

</style>
<style scoped>
  /* Animation CSS */
 .fade-enter, .slide-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 0.25s ease-out;
  }

  .fade-leave-active {
    transition: opacity 0.25s ease-out;
    opacity: 0;
  }

  .slide-enter-active {
    animation: slide-in 0.25s ease-out forwards;
    transition: opacity 0.25s;
  }

  @keyframes slide-in {
    from {
      transform: translateX(20px);
    }
    to {
      transform: rotate(0);
    }
  }

  /* Submit loader */

  .lds-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    border: 1px solid #5031D0;
  }
  .lds-loader div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-loader div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-loader {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }

  /* Vue Dropzone CSS */
  .vue-dropzone {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 300px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px dashed #97D4FF;
    border-radius: 10px;
    background-color: #E8F8FF;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    color: #4D497E;
    font-size: 22px;
    padding: 100px;
  }

  .vue-dropzone:hover {
    background-color: rgba(63, 119, 225, 0.28);
  }

  /* Page CSS */

  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .underline-link {
    cursor:pointer;
    text-decoration: none;
    text-decoration: underline;
  }

  .nav-buttons {
    padding-top: 0px !important;
  }

  .uploadicon {
    width: 40px;
    margin-bottom: 25px;
  }

  .new-section-page {
    margin-top: 25px;
  }

  .herolist {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .list-item {
    margin-bottom: 15px;
  }

  .daterow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 800px;
    -webkit-box-pack: justify;
    -webkit-justify-content: center;
    -ms-flex-pack: justify;
    justify-content: center;
  }

  .daterow-nav-buttons {
    justify-content: center;
  }

  .datepick {
    text-align: center;
  }

  .employee-label {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 0px;
  }

  .input-records-title {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .vax-count {
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
  }

  .prior-input {
    width: 100%;
    background: #F3F3F3;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
  }

  .prior-input span {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #3C3C3C;
  }

  .prior-input table {
    margin: 0;
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;

  }

  .prior-input td {
    text-align: center;
    max-width: 100px;
    width: 50%;
    height: 20px;
    padding-bottom: 15px;
  }

  .delete-btn {
    width: 150px;
    height: 30px;
    font-size: 12.5px;
    font-weight: 500;
    align-items: center;
    display: inline-grid;
    background: #F3F3F3;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .delete-btn:hover {
    background-color: #5031D0;
    color: #FFFFFF;
  }

  .deletefirtsdose-btn {
    width: 200px;
    font-size: 12.5px;
    font-weight: 500;
    align-items: center;
    display: inline-grid;
    background: #F3F3F3;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none;
    margin-bottom: 15px;
    height: 35px;
  }

  .dropzone-custom-content {
    line-height: 27px;
  }

  .admin-screen-warn {
    background: #ECECEC;
    border-radius: 8px;
    padding: 15px 25px;
  }

  .hep-description {
    font-size: 14px;
  }

  @media screen and (max-width: 479px) {
    /* Vue Dropzone CSS */
    .vue-dropzone  {
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
      text-align: center;
      padding: 0px;
    }

    /* Page CSS */
    .daterow {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .datepick {
      width: 100%;
    }

  }

</style>
